import React, { useEffect, useState } from "react"
import { useInputChange } from "../custom-hook/useForm"
import AccountInput from "./accountInput"
import CountryForm from "./countryInput"
import DateForm from "./dateForm"
import "../assets/styles/components/businessInfo.scss"
import { useSelector } from "react-redux"

const BusinessInfo = (props: { [keys: string]: any }) => {
  const { setIsDisabled, formInputRef } = props

  const [input, handleInputChange] = useInputChange()
  const [sameName, setSameName] = useState(true)
  const [sameAddress, setSameAddress] = useState(true)

  const kycProfileStatus = useSelector((state: any) => state.profileKyc.kycProfileStatus)
  const businessInfo = kycProfileStatus?.profile?.businessInfo

  const isNotCompleted =
    !input.legalBusinessName ||
    !input.businessRegNumber ||
    !input.taxIdNumber ||
    !input.officeCountryRegion ||
    !input.officeStreet

  useEffect(() => {
    if (businessInfo) {
      const { tradingAddress, officeAddress, ...newObject } = businessInfo
      Object.assign(formInputRef.current, tradingAddress, officeAddress, newObject)
    }

    setIsDisabled(isNotCompleted)
    if (Object.keys(input).length != 0) Object.assign(formInputRef.current, input)
  }, [isNotCompleted, input, businessInfo])

  const handleSameName = () => {
    setSameName(!sameName)
    const sameTradingName = { currentTarget: { name: "sameName", value: !sameName } }
    handleInputChange(sameTradingName)
  }

  const handleSameAddress = () => {
    setSameAddress(!sameAddress)
    const sameTradingAddress = { currentTarget: { name: "sameAddress", value: !sameAddress } }
    handleInputChange(sameTradingAddress)
  }

  return (
    <div className="business-info">
      <h3 className="h3-heading">Add your business info</h3>
      <span className="desc-1">
        This should match the most up-to-date business registration documents.
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </span>
      <div className="section-1">
        <div className="desc-2">
          <h6>Legal business name</h6>
          <p className="description">The official, registered name.</p>
        </div>

        <AccountInput
          name={"legalBusinessName"}
          type={"text"}
          label={"Legal Business Name"}
          placeholder={" "}
          onChange={handleInputChange}
          required={true}
        />
        <span className="subscript">Ex: Brooi, Inc.</span>
      </div>

      <div className="checkbox">
        <AccountInput
          name={"sameName"}
          checked={sameName}
          type={"checkbox"}
          onChange={handleSameName}
          extraText={"Same as the trading name, also called “trading as” (T/A)"}
        />
      </div>
      {sameName ? null : (
        <div className="section-2">
          <div className="desc-2">
            <h6>Trading name</h6>
            <p className="description">Some businesses use a second name, also called “trading as” (T/A).</p>
          </div>
          <AccountInput
            name={"tradingName"}
            type={"text"}
            label={"Trading Name"}
            onChange={handleInputChange}
            required={true}
          />
          <span className="subscript">Ex: Brooi, Inc.</span>
        </div>
      )}

      <div className="section-2">
        <div className="desc-2">
          <h6>Business registration number</h6>
          <p className="description">
            The unique ID for the business. It can usually be found on government-issued registration documents or
            public records.
          </p>
        </div>
        <AccountInput
          name={"businessRegNumber"}
          type={"text"}
          label={"Business Registration Number"}
          onChange={handleInputChange}
          required={true}
        />
      </div>

      <div className="section-2">
        <div className="desc-2">
          <h6>Tax identification number (TIN)</h6>
          <p className="description">The unique ID used for tax reporting.</p>
        </div>
        <div className="name-form">
          <AccountInput
            name={"taxIdNumber"}
            type={"text"}
            label={"Tax Identification Number"}
            onChange={handleInputChange}
            required={true}
          />
          <AccountInput
            name={"vatId"}
            type={"text"}
            label={"VAT ID (if applicable)"}
            onChange={handleInputChange}
            required={"none"}
          />
        </div>
      </div>

      <div className="section-2">
        <div className="desc-2">
          <h6>Date of incorporation (optional)</h6>
          <p className="description">This is usually found on government-issued registration documents.</p>
        </div>
        <DateForm name={"dateOfIncorporation"} onChange={handleInputChange} required={false} />
      </div>

      <div className="section-2">
        <div className="desc-2">
          <h6>Registered office address</h6>
          <p className="description">The official business address, as shown on public records.</p>
        </div>
        <div className="subsection">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">Country / Region</p>
                <CountryForm name={"officeCountryRegion"} onChange={handleInputChange} required={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="subsection">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">Street</p>
                <AccountInput name={"officeStreet"} onChange={handleInputChange} type={"text"} required={true} />
                <span className="subscript">House name/number + street/road</span>
              </div>
            </div>
          </div>
        </div>
        <div className="subsection">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">Apt, Suite. (optional)</p>
                <AccountInput name={"officeApt_suite"} onChange={handleInputChange} type={"text"} required={"none"} />
                <span className="subscript">Apt., suite, building access code</span>
              </div>
            </div>
          </div>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">City</p>
                <AccountInput name={"officeCity"} onChange={handleInputChange} type={"text"} required={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">State</p>
                <AccountInput name={"officeState"} onChange={handleInputChange} type={"text"} required={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">ZIP Code</p>
                <AccountInput name={"officeZipCode"} onChange={handleInputChange} type={"text"} required={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>

      <AccountInput
        name={"sameAddress"}
        type={"checkbox"}
        checked={sameAddress}
        onChange={handleSameAddress}
        extraText={"Same as trading address, which is used for invoices and banking"}
      />

      {sameAddress ? null : (
        <div className="section-2">
          <div className="desc-2">
            <h6>Trading address</h6>
            <p className="description">
              The address used for invoices and banking. This is only required if it’s different from the registered
              office address.
            </p>
          </div>
          <div className="subsection">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <div className="wrapper-3">
                  <p className="label">Country / Region</p>
                  <CountryForm name={"tradingCountryRegion"} onChange={handleInputChange} required={true} />
                </div>
              </div>
            </div>
          </div>
          <div className="subsection">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <div className="wrapper-3">
                  <p className="label">Street</p>
                  <AccountInput name={"tradingStreet"} onChange={handleInputChange} type={"text"} required={true} />
                  <span className="subscript">House name/number + street/road</span>
                </div>
              </div>
            </div>
          </div>
          <div className="subsection">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <div className="wrapper-3">
                  <p className="label">Apt, Suite. (optional)</p>
                  <AccountInput
                    name={"tradingApt_suite"}
                    onChange={handleInputChange}
                    type={"text"}
                    required={"none"}
                  />
                  <span className="subscript">Apt., suite, building access code</span>
                </div>
              </div>
            </div>
          </div>

          <div className="subsection-2">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <div className="wrapper-3">
                  <p className="label">City</p>
                  <AccountInput name={"tradingCity"} onChange={handleInputChange} type={"text"} required={true} />
                </div>
              </div>
            </div>
          </div>

          <div className="subsection-2">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <div className="wrapper-3">
                  <p className="label">State</p>
                  <AccountInput name={"tradingState"} onChange={handleInputChange} type={"text"} required={true} />
                </div>
              </div>
            </div>
          </div>

          <div className="subsection-2">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <div className="wrapper-3">
                  <p className="label">ZIP Code</p>
                  <AccountInput name={"tradingZipCode"} onChange={handleInputChange} type={"text"} required={true} />
                </div>
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
      )}

      <div className="section-3">
        <div className="desc-3">
          <h6>Website (optional)</h6>
        </div>
        <AccountInput name={"website"} onChange={handleInputChange} type={"text"} required={"none"} />
      </div>
    </div>
  )
}

export default BusinessInfo
