import React, { useEffect, useRef, useState } from "react"
import AccountLayout from "../../../components/accountLayout"
import BusinessInfo from "../../../components/businessInfo"

const BusinessInfoPage = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const formInputRef = useRef({})

  useEffect(() => {
    formInputRef.current = { ...formInputRef.current, formLevel: 2 }
  }, [])

  return (
    <AccountLayout
      to="/account-details/status"
      formInput={formInputRef.current}
      title="Add business info"
      isDisabled={isDisabled}
    >
      <form id="form-id">
        <BusinessInfo formInputRef={formInputRef} setIsDisabled={setIsDisabled} />
      </form>
    </AccountLayout>
  )
}

export default BusinessInfoPage
